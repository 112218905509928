import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'platform' },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    },
    {
        path: 'offers/:id',
        loadChildren: () => import('./pages/offer/offer.module').then((m) => m.OfferPageModule),
    },
    {
        path: 'offers/:id-:slug',
        loadChildren: () => import('./pages/offer/offer.module').then((m) => m.OfferPageModule),
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    },
    {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module').then((m) => m.SearchPageModule),
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
    },
    {
        path: 'create-offer',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/create-offer/create-offer.module').then((m) => m.CreateOfferPageModule),
    },
    {
        path: 'cart',
        loadChildren: () => import('./pages/shopping-cart/shopping-cart.module').then((m) => m.ShoppingCartPageModule),
    },
    {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqPageModule),
    },
    {
        path: 'checkout',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutPageModule),
    },
    {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
    },
    {
        path: 'platform',
        loadChildren: () => import('./pages/platform/platform.module').then((m) => m.PlatformPageModule),
    },
    {
        path: 'support',
        loadChildren: () => import('./pages/support/support.module').then((m) => m.SupportPageModule),
    },
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
    },
    {
        path: 'transactions/:code',
        loadChildren: () => import('./pages/transaction/transaction.module').then((m) => m.TransactionPageModule),
    },
    // slug only : always last
    {
        path: ':slug',
        loadChildren: () => import('./pages/content-page/content-page.module').then((m) => m.ContentPagePageModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
