import Decimal from 'decimal.js';
import * as moment from 'moment/moment';
import { Company } from './company';
import { ShipmentObject } from './delivery';
import { Offer, PriceObject } from './offer';
import { OfferVariant } from './offer-definition';
import { User } from './user';

export class ShoppingCartLine {
    offer: Offer;
    offerVariant: OfferVariant;
    quantity: number;
    total?: PriceObject;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            this.offer = new Offer(data.offer);
            this.offerVariant = new OfferVariant(data.offerVariant);
            // Fix for quantity
            this.quantity = data.quantity || 1;
        }
    }
}

export class ShoppingCartGroup {
    lines: ShoppingCartLine[];
    user: User;
    company: Company;
    deliveryEta: string;
    deliveryPrice: PriceObject;
    linesTotal?: PriceObject;
    deliveryTotal?: PriceObject;
    total?: PriceObject;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.lines = obj.lines.map((line: any) => new ShoppingCartLine(line));
        }
    }

    setDelivery(deliveryCost: { cost: string; currency?: string; eta: string }) {
        this.deliveryPrice = {
            price: new Decimal(deliveryCost.cost.replace(',', '')),
            currency: deliveryCost.currency || 'TRY',
        };
        // Format : Thursday
        this.deliveryEta = moment(deliveryCost.eta).format('dddd DD MMMM');
        this.deliveryTotal = {
            price: new Decimal(deliveryCost.cost.replace(',', '')),
            currency: deliveryCost.currency || 'TRY',
        };
    }
}

export class ShoppingCart {
    total?: PriceObject;
    groups: ShoppingCartGroup[] = [];
    lines: ShoppingCartLine[] = [];
    wholesale: boolean = false;
    currency: string = 'TRY';

    constructor(data?: Partial<ShoppingCart>) {
        if (data) {
            Object.assign(this, data);
            this.lines = data.lines?.map((line: Partial<ShoppingCartLine>) => new ShoppingCartLine(line)) || [];
            this.updateGroups();
        }
    }

    get isEmpty(): boolean {
        return this.lines.length === 0;
    }

    get count(): number {
        return this.lines.reduce((total, line) => total + line.quantity, 0);
    }

    add(offer: Offer, offerVariant: OfferVariant, quantitySelected: number) {
        let line = this.lines.find((l) => l.offer.id === offer.id && l.offerVariant?.id === offerVariant?.id);
        if (line) {
            line.quantity += quantitySelected;
        } else {
            line = new ShoppingCartLine({
                offer,
                offerVariant,
                quantity: quantitySelected,
            });
            this.lines.push(line);
        }

        this.updateGroups();
    }

    updateGroups() {
        const groups: ShoppingCartGroup[] = [];
        this.lines.forEach((line) => {
            console.log(line);
            // Group by company first or if not available then by user
            let group = groups.find((g) => g.company?.id === line.offer.company?.id || g.user?.id === line.offer.user?.id);
            if (!group) {
                group = new ShoppingCartGroup({
                    lines: [line],
                    user: line.offer.user,
                    company: line.offer.company,
                    deliveryEta: null,
                    deliveryPrice: 0,
                });
                groups.push(group);
            } else {
                group.lines.push(line);
            }
        });
        this.groups = groups;
    }

    setLineTotal(): void {
        // Update lines in groups
        this.groups.forEach((group) => {
            group.lines.forEach((line) => {
                line.total = line.offer.computePrice(this.wholesale, line.quantity, line.offerVariant?.id);
            });
        });
    }

    setGroupTotal(): void {
        this.groups.forEach((group) => {
            // Get all line totals
            group.linesTotal = group.lines.reduce(
                (total, line) => {
                    return {
                        price: total.price.add(line.total.price),
                        currency: line.total.currency,
                        from: total.from || line.total.from,
                    };
                },
                {
                    price: new Decimal(0),
                    currency: null,
                    from: false,
                } as PriceObject,
            );
            group.total = { ...group.linesTotal };
            // Add delivery cost
            if (group.deliveryTotal) {
                group.total.price = group.total.price.add(group.deliveryTotal.price);
            }
            console.log(group);
        });
    }

    setTotal(): void {
        // Get all group totals
        this.total = this.groups.reduce(
            (total, group) => {
                return {
                    price: total.price.add(group.total.price),
                    currency: group.total.currency,
                    from: total.from || group.total.from,
                };
            },
            {
                price: new Decimal(0),
                currency: null,
                from: false,
            } as PriceObject,
        );
    }

    get toShipments(): ShipmentObject {
        const shipments: ShipmentObject = { shipments: [], currency: this.currency };
        for (const group of this.groups) {
            let weight = 0;
            let deci = new Decimal(0);
            let daysToShip = 0;
            for (const line of group.lines) {
                console.log('Packages', line.offer.packages);
                weight += line.offer.packages.reduce((total, pack) => total + pack.weight * line.quantity, 0);
                deci = deci.add(line.offer.packages.reduce((total, pack) => total.add(pack.deci), new Decimal(0)).mul(line.quantity));
                daysToShip = Math.max(daysToShip, line.offer.daysToShip);
            }
            shipments.shipments.push({
                weight,
                deci: deci.toString(),
                provider: 'PTT',
                daysToShip,
            });
        }

        return shipments;
    }
}
