export const environment = {
    version: '1.4.0',
    firebase: {
        projectId: 'alsat-tr',
        appId: '1:821259184362:web:a884966701ffedacb1cf93',
        storageBucket: 'alsat-tr.appspot.com',
        apiKey: 'AIzaSyAg6M1aYtTmYouKTyyAakIQy8mwEELn6XI',
        authDomain: 'alsat-tr.firebaseapp.com',
        messagingSenderId: '821259184362',
    },
    locales: ['tr', 'fr'],
    countries: ['TR'],
    production: true,
    apiUrl: 'https://mng.avrazya.com',
    FIREBASE_STORAGE_URL: 'https://storage.googleapis.com/alsat-tr.appspot.com/',
    GOOGLE_PLACES_API_KEY: 'AIzaSyCNd1EAWlPCkiY-97LauG8E7YhnEIdJO0w',
};
