import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

export class DocumentUtils {
    static DOCUMENT_TYPES = [
        {
            label: _('Signature authorization'),
            value: 'signature_authorization',
        },
        { label: _('Tax registration'), value: 'tax_registration' },
        // { label: _('Sale contract'), value: 'sale_contract' },
        { label: _('Identity proof'), value: 'identity_proof' },
        {
            label: _('Real estate license'),
            value: 'real_estate_license',
            companyActivities: ['real_estate'],
        },
        {
            label: _('Real estate authorization'),
            value: 'real_estate_authorization',
            companyActivities: ['real_estate'],
        },
        {
            label: _('Car dealership authorization'),
            value: 'car_dealership_authorization',
            companyActivities: ['car_dealership'],
        },
        { label: _('Company registration'), value: 'company_registration' },
    ];

    static requiresExpirationDate(documentType: string): boolean {
        return ['identity_proof'].includes(documentType);
    }

    static b64toBlob(b64Data: string, contentType: string) {
        contentType = contentType || '';
        const sliceSize = 512;
        const byteCharacters = atob(b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    static getLabelFromType(type: string) {
        const documentType = DocumentUtils.DOCUMENT_TYPES.find((d) => d.value === type);
        return documentType ? documentType.label : 'Unknown';
    }
}
