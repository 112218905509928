import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable, filter, map } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private readonly authService: AuthService,
        private readonly navCtrl: NavController,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authService.authInitialized.pipe(filter((initialized) => !!initialized)).pipe(
            map(() => {
                if (!this.authService.isAuthenticated()) {
                    this.navCtrl.navigateForward('/signup');
                    // Get asked route to store
                    const routeUrl = state.url;
                    this.authService.saveRedirectAfterLogin(routeUrl);
                    return false;
                }
                return true;
            }),
        );
    }
}
