import { AbstractControl, FormGroup } from '@angular/forms';

export function ValidateCompositionFormGroup(control: AbstractControl): any {
    const controls = Object.keys((control as FormGroup).controls);
    let total = 0;
    for (let name of controls) {
        const value = (control as FormGroup).controls[name].value;
        if (value.percentage <= 0 || value.percentage > 100) {
            control.get(name + '.percentage').setErrors({ invalidPercentage: true });
            return { invalidPercentage: true };
        } else {
            control.get(name + '.percentage').setErrors(null);
        }
        total += value.percentage;
    }

    if (total !== 100) {
        for (let name of controls) {
            control.get(name + '.percentage').setErrors({ invalidTotal: true });
        }

        return null;
    }

    return null;
}
