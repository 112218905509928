import { Category, CategoryProperty } from '../models/category';
import { AppPlatform } from '../models/platform';

export class CategoryUtils {
    private static categories: { [key: number]: Category } = {};

    static getById(id: number): Category {
        return CategoryUtils.categories[id];
    }

    /**
     * Set categories in the static variable (used to get categories by id)
     */
    static setCategories(categories: Category[]): void {
        for (const category of categories) {
            if (category.isEnabled) {
                CategoryUtils.categories[category.id] = category;
                CategoryUtils.setCategories(category.children);
            }
        }
    }

    /**
     * Find the root category of a category : a root category is a category that has no parent
     */
    static findRootCategory(category: Category): Category {
        if (!category.parent) {
            return category;
        }

        return this.findRootCategory(this.getById(category.parent));
    }

    /**
     * Find all promoted categories (across all levels)
     */
    static findPromoted(platform: 'marketplace' | 'gazette' | 'wholesale'): Category[] {
        return Object.values(CategoryUtils.categories).filter((c) => c.isPromoted === true && c.hasOffersOnPlatform(platform));
    }

    /**
     * Find the root category and return mediaTag property
     */
    static hasMediaTag(category: Category): string {
        return this.findRootCategory(category)?.mediaTag || null;
    }

    static getFullName(category: Category): string {
        if (category.parent) {
            return this.getFullName(this.getById(category.parent)) + ' • ' + category.name;
        }
        return category.name;
    }

    static getFlatList(platform: AppPlatform = null): { name: string; id: number }[] {
        // Find all categories without children and concat the name of all parents
        const flatList = [];
        for (const category of Object.values(CategoryUtils.categories)) {
            if (platform && !category.isPlatform(platform)) {
                continue;
            }
            if (category.children.length === 0) {
                flatList.push({
                    name: CategoryUtils.getFullName(category),
                    id: category.id,
                });
            }
        }

        return flatList;
    }

    /**
     * Find first parent with property and return its value
     */
    static findProperty(category: Category, property: CategoryProperty): any {
        // Shome property should be returned as is
        if (property === 'shippable') {
            return category.shippable;
        }

        // Other properties should be returned from the root category
        const value = (category as any)[property] || null;
        if (value) {
            return value;
        }
        if (category.parent) {
            return this.findProperty(this.getById(category.parent), property);
        }
        return null;
    }

    static hasTag(category: Category, tag: string): boolean {
        if (category.tags?.includes(tag)) {
            return true;
        }

        if (category.parent) {
            return this.hasTag(this.getById(category.parent), tag);
        }

        return false;
    }
}
