import * as moment from 'moment';
import { environment } from '../../environments/environment';

export type MediaType = 'document' | 'avatar' | 'avatar_company' | 'search_header' | 'search_header_company' | 'offer' | 'invoice';

export class Media {
    id: number;
    path: string;
    mimeType: string;
    size: number;
    gcsUri: string;
    tag: string;
    type: MediaType;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}

export class Document {
    id: number;
    type: string;
    media: Media;
    expiresAt: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.media = obj.media ? new Media(this.media) : null;
        }
    }

    downloadUrl(): string {
        return environment.apiUrl + '/documents/' + this.id + '/download';
    }

    get expiresAtFormatted(): string {
        return this.expiresAt ? moment(this.expiresAt).format('DD/MM/YYYY') : null;
    }
}
