import { Injectable } from '@angular/core';
import { AppPlatform } from '../models/platform';

@Injectable({
    providedIn: 'root',
})
export class ContextService {
    private platform: AppPlatform;

    getPlatform(): AppPlatform {
        if (this.platform) {
            return this.platform;
        }

        const route = (window as any).window.location.pathname;

        // if url starts with /gazette or /wholesale, set platform accordingly.
        // Otherwise, set it to marketplace
        if (route.startsWith('/gazette')) {
            this.platform = 'gazette';
        } else if (route.startsWith('/wholesale')) {
            this.platform = 'wholesale';
        } else {
            this.platform = 'marketplace';
        }

        return this.platform;
    }

    isWholesale(): boolean {
        return this.platform === 'wholesale';
    }
}
