<ion-content>
    <div class="modal-inner-content p-4">
        <div class="d-flex justify-content-end w-100">
            <span class="bi bi-x-circle-fill fs-1 clickable" (click)="close('cancel')"></span>
            <!--      <ion-icon name="close-outline" class="text-dark fs-1 clickable" (click)="close('cancel')"></ion-icon>-->
        </div>
        <div>
            <div class="d-flex justify-content-center align-items-center fs-2">
                <span class="bi" [ngClass]="content?.icon || 'bi-x-circle-fill'"></span>
            </div>
            <h1 class="text-center py-4">
                {{ content?.title | translate }}
            </h1>
            <div class="modal-inner-content-text text-center">
                {{ content?.message | translate }}
            </div>
        </div>
        <div class="d-flex flex-column gap-3 align-items-center mt-4 w-100">
            <button class="btn btn-outline-primary w-100" *ngIf="content?.cancelText" (click)="close('cancel')" type="button">
                {{ content.cancelText | translate }}
            </button>
            <button class="btn btn-primary w-100" *ngIf="content?.confirmText" (click)="close('confirm')" type="button">
                {{ content.confirmText | translate }}
            </button>
        </div>
    </div>
</ion-content>
