import { inject, Injectable } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from '@angular/fire/auth';
import { map, Observable } from 'rxjs';
import { Media } from '../models/media';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly firebaseAuth = inject(Auth);
    isRegistered = true;

    constructor(
        private apiService: ApiService,
        private authService: AuthService,
    ) {
        // Check if the user is logged in and refresh the user data
        this.firebaseAuth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    this.authService.token = token;
                    if (this.isRegistered) {
                        this.refreshUserData();
                    }
                    this.authService.authInitialized.next(true);
                });
            } else {
                this.authService.authInitialized.next(true);
            }
        });
    }

    login(data: { email: string; password: string }): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // First we call the firebase login
            signInWithEmailAndPassword(this.firebaseAuth, data.email, data.password)
                .then((userCredential) => {
                    resolve(true);
                })
                .catch((error) => {
                    // If the firebase login fails, we reject the promise
                    reject(error);
                });
        });
    }

    private getMe(resolve: (user: User) => void, reject: (error: any) => void): void {
        this.apiService.get<User>('/api/me', true).subscribe({
            next: (user) => {
                user = new User(user);
                this.authService.login(user);
                resolve(user);
            },
            error: (error) => {
                this.firebaseAuth.signOut();
                reject(undefined);
            },
        });
    }

    async refreshUserData(): Promise<User> {
        return new Promise((resolve, reject) => this.getMe(resolve, reject));
    }

    createProAccount(data: any): Observable<any> {
        return this.apiService.post('/api/pro-account', data);
    }

    register(data: any): Promise<User> {
        return new Promise((resolve, reject) => {
            // First, register on firebase
            createUserWithEmailAndPassword(this.firebaseAuth, data.email, data.password).then(
                async (userCredential) => {
                    // Remove the password from the data and add the firebase uid
                    delete data.password;
                    data.firebaseUid = userCredential.user.uid;
                    // Get the firebase token
                    const token = await userCredential.user.getIdToken();
                    // Then, register on the API
                    this.apiService.post<User>('/api/register?idToken=' + token, data).subscribe({
                        next: (user) => {
                            user = new User(user);
                            this.authService.login(user);
                            resolve(user);
                        },
                        error: (error) => {
                            reject(error);
                        },
                    });
                },
                (error) => {
                    // If the firebase register fails, we reject the promise
                    reject(error);
                },
            );
        });
    }

    updateMe(obj?: any): Observable<User> {
        return this.apiService.put<User>('/api/me', obj).pipe(
            map((data) => {
                const user = new User(data);
                this.authService.login(user);
                return user;
            }),
        );
    }

    updateProfilePicture(media: Media): Observable<User> {
        return this.updateMe({ picture: media.id });
    }

    getPublicUser(userId: number) {
        return this.apiService.get<User>('/api/users/' + userId).pipe(map((data) => new User(data)));
    }

    anonymizeMe(): Observable<User> {
        const userId = this.authService.userLoggedIn.value.id;
        return this.apiService.post<User>(`/api/users/${userId}/anonymize`, {}, true);
    }
}
