import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { ShoppingCartService } from '../../services/shopping-cart.service';

@Component({
    selector: 'app-footer-mobile',
    templateUrl: './footer-mobile.component.html',
    styleUrls: ['./footer-mobile.component.scss'],
})
export class FooterMobileComponent implements OnInit {
    user: User;
    shoppingCartLineCount: number = 0;

    constructor(
        private readonly authService: AuthService,
        private readonly shoppingCartService: ShoppingCartService,
    ) {}

    ngOnInit() {
        this.authService.userLoggedIn.subscribe({
            next: (user) => {
                this.user = user;
            },
        });
        this.shoppingCartService.shoppingCart.subscribe((shoppingCart) => {
            if (shoppingCart) {
                this.shoppingCartLineCount = shoppingCart.count;
            }
        });
    }
}
