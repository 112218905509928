import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import * as moment from 'moment';
import { Filter, FilterValue, FilterValueType } from './filter';

export class OfferVariantValuePost {
    filterValue: number; // filter value id
    filterValueEntity?: FilterValue = null;
    integerValue: number;
    textValue: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    get code() {
        const value = this.filterValue || this.filterValueEntity?.code || this.textValue || this.integerValue;
        return `${value}`;
    }

    get name(): string {
        const value = this.filterValueEntity?.value || this.textValue || this.integerValue || this.filterValue;
        return `${value}`;
    }
}

export class PostOfferVariantDefinition {
    filter: number;
    filterEntity?: Filter = null;
    value: OfferVariantValuePost;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.value = obj.value ? new OfferVariantValuePost(obj.value) : null;
        }
    }

    get code() {
        const id = this.filter || this.filterEntity.id;
        return `${id}_${this.value.code}`;
    }

    get name(): string {
        const id = this.filterEntity?.name || this.filter;

        return `${id}: ${this.value.name}`;
    }

    get rawValue(): number | string | string[] {
        if (this.value?.filterValueEntity && (this.filterEntity?.type === 'single_choice' || this.filterEntity?.type === 'multi_choice')) {
            return this.value.filterValueEntity.code;
        }
        if (this.value.integerValue) {
            return this.value.integerValue;
        }
        return this.value.textValue;
    }
}

export class OfferVariantPost {
    definitions: PostOfferVariantDefinition[] = [];

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.definitions = obj.definitions ? this.definitions.map((d) => new PostOfferVariantDefinition(d)) : [];
        }
    }

    get code() {
        return this.definitions
            .map((d) => d.code)
            .filter((c) => c !== undefined)
            .join('|');
    }

    get name(): string[] {
        return this.definitions.map((d) => d.name).filter((c) => c !== undefined);
    }
}

export class OfferVariant {
    id: number;
    definitions: OfferDefinition[] = [];
    price: string;
    stocks: number = 0;
    stocksPending: number = 0;
    sku: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
            this.definitions = obj.definitions ? this.definitions.map((d) => new OfferDefinition(d)) : [];
        }
    }

    static setEntities(variants: OfferVariant[], filters: Filter[]): void {
        for (let variant of variants) {
            for (let definition of variant.definitions) {
                definition.filterEntity = filters.find((f) => f.id === definition.filter);
            }
        }
    }

    get name() {
        return this.definitions.map((d) => d.name).join(', ');
    }
}

export class OfferDefinition {
    filter: number; // filter id
    textValue: string;
    integerValue: number;
    filterValues: number[]; // filter values ids
    filterEntity: Filter = null;
    dateValue: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    static create(filter: Filter, value: any): OfferDefinition[] {
        const data: any = { filter: filter.id };
        switch (filter.type) {
            case 'boolean':
                data.integerValue = value ? 1 : 0;
                return [data];
            case 'text':
                if (!value) {
                    return [];
                }
                data.textValue = value;
                return [data];
            case 'decimal':
                data.integerValue = value * 100;
                return [data];
            case 'integer':
                data.integerValue = value;
                return [data];
            case 'multi_choice':
            case 'single_choice':
                data.filterValues = FilterValue.codeToArrayId(filter, value);
                if (data.filterValues.length === 0) {
                    return [];
                }
                return [data];
            case 'date':
                data.textValue = value;
                if (!data.textValue) {
                    return [];
                }
                return [data];
            case 'year':
                data.integerValue = value;
                if (!data.textValue) {
                    return [];
                }
                return [data];
            case 'composition':
                const definitions: any = [];
                for (const [key, val] of Object.entries(
                    value as {
                        [key: string]: { percentage: number; value: string[] };
                    },
                )) {
                    let definition: any = { filter: filter.id };
                    // First value is the filter value
                    const filterValue = FilterValue.codeToArrayId(filter, val.value)[0];
                    if (!filterValue) {
                        continue;
                    }
                    definition.filterValues = [filterValue];
                    // Second value is the percent
                    definition.integerValue = val.percentage;
                    definitions.push(definition);
                }

                return definitions;
            case 'opening_hours':
            default:
                return [];
        }
    }

    toDisplayValue(filter: Filter): string | string[] {
        switch (filter.type) {
            case 'boolean':
                return this.integerValue === 1 ? _('Yes') : _('No');
            case 'text':
                return this.textValue;
            case 'decimal':
                return (this.integerValue / 100).toFixed(2);
            case 'integer':
                return this.integerValue?.toString() || null;
            case 'multi_choice':
            case 'single_choice':
                // Map ids to values names
                return this.filterValues
                    .map((vId) => filter.filterValues.find((v) => v.id === vId))
                    .map((v) => v?.value)
                    .filter((v) => v !== undefined);
            case 'composition':
                return this.integerValue + '% ' + filter.filterValues.find((v) => v.id === this.filterValues[0]).value; // + ' ' + this.integerValue + '%';
            default:
                return 'NaN';
        }
    }

    static canShow(filter: Filter, definitions: OfferDefinition[]) {
        for (const d of definitions) {
            if (filter.type === 'boolean' && d.integerValue === 0) {
                return false;
            }
            if (filter.type === 'multi_choice' || filter.type === 'single_choice') {
                if (d.filterValues?.length === 1) {
                    const v = filter.filterValues.find((v) => v.id === d.filterValues[0]);
                    return !v?.code?.startsWith('noshow_');
                }
            }
            if (d.integerValue !== null || d.textValue !== null || d.filterValues?.length > 0) {
                return true;
            }
        }

        return false;
    }

    static createAll(filters: Filter[], entries: { [key: string]: FilterValueType }): OfferDefinition[] {
        const definitions = [];
        // Convert definitions
        for (const [key, value] of Object.entries(entries)) {
            // We need to find the filter id
            const filterId = Filter.codeToId(key);
            // Find the filter
            const filter = filters.find((f) => f.id === filterId);
            definitions.push(...OfferDefinition.create(filter, value));
        }

        return definitions;
    }

    get value(): string {
        if (this.filterValues?.length > 0) {
            return this.filterValues.join(',');
        }
        if (this.integerValue) {
            return this.integerValue.toString();
        }
        if (this.dateValue) {
            return this.dateValue;
        }
        return this.textValue;
    }

    get name(): string {
        return this.filterEntity?.name || this.filter.toString();
    }

    get valueFormatted(): string {
        if (this.filterValues?.length > 0) {
            if (this.filterEntity) {
                return this.filterValues.map((v) => this.filterEntity.filterValues.find((fv) => fv.id === v).value).join(', ');
            }

            return this.filterValues.join(', ');
        }

        if (this.integerValue) {
            return this.integerValue.toString();
        }

        if (this.dateValue) {
            return moment(this.dateValue).format('DD/MM/YYYY');
        }

        return this.textValue;
    }
}
