export class Vat {
    id: number;
    name: string;
    rate: number; // Store as an integer, e.g. 21% is stored as 2100
    country: string; // ISO 3166-1 alpha-2 country code (only apply VAT for this country)

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
        }
    }
}
