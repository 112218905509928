export class ExchangeRate {
    currencySource: string;
    currencyTarget: string;
    rate: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}
