import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Invoice, Order, PostOrder } from '../models/order';
import { Paginated } from '../models/papginated';
import { Vat } from '../models/vat';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    public readonly vats = new BehaviorSubject<Vat[]>(undefined);

    constructor(private readonly apiService: ApiService) {}

    getVats(country: string): Observable<Vat[]> {
        return this.apiService
            .get<Vat[]>('/api/vats/' + country)
            .pipe(map((v) => v.map((vat) => new Vat(vat))))
            .pipe(tap((vats) => this.vats.next(vats)));
    }

    placeOrder(data: PostOrder): Observable<Order[]> {
        return this.apiService.post<Order[]>('/api/orders', data).pipe(map((orders) => orders.map((order) => new Order(order))));
    }

    /**
     * Get all orders for the current user (orders that have been placed by the current user)
     */
    getOrders(): Observable<Paginated<Order>> {
        return this.apiService.get<Paginated<Order>>('/api/orders').pipe(
            map((result) => {
                result.items = result.items.map((order) => new Order(order));
                return result;
            }),
        );
    }

    /**
     * Get all sales for the current user (orders that have been placed by other users)
     */
    getSales(): Observable<Paginated<Order>> {
        return this.apiService.get<Paginated<Order>>('/api/sales').pipe(
            map((result) => {
                result.items = result.items.map((order) => new Order(order));
                return result;
            }),
        );
    }

    getOrder(ref: string): Observable<Order> {
        return this.apiService.get<Order>('/api/orders/' + ref).pipe(map((order) => new Order(order)));
    }

    downloadInvoice(id: number) {
        return this.apiService.get<{ url: string }>('/api/invoices/' + id + '/download');
    }

    createInvoice(data: { orderId: number; mediaId: number }): Observable<Invoice> {
        return this.apiService
            .post('/api/orders/' + data.orderId + '/invoices', {
                media: data.mediaId,
            })
            .pipe(map((invoice) => new Invoice(invoice)));
    }

    getDownloadUrlContractInvoice(ref: string) {
        return environment.apiUrl + '/api/orders/' + ref + '/sale-contract';
    }
}
