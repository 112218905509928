import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SelectComponent } from './select/select.component';

@NgModule({
    declarations: [SelectComponent],
    imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule],
    exports: [SelectComponent],
})
export class CustomFormModule {}
