import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Media } from '../models/media';
import { AuthService } from './auth.service';
import { ContextService } from './context.service';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(
        private readonly authService: AuthService,
        private readonly http: HttpClient,
        private readonly translateService: TranslateService,
        private readonly contextService: ContextService,
    ) {}

    static objectToQueryString(obj: any): string {
        const keyValuePairs = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null) {
                keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
            }
        }
        return keyValuePairs.join('&');
    }

    static createUrl(url: string): string {
        // remove trailing slash, double slashes
        return environment.apiUrl + url.replace(/\/$/, '').replace(/\/\//, '/');
    }

    post<T>(url: string, data: any, auth: boolean = true): Observable<T> {
        return this.http.post<T>(ApiService.createUrl(url), data, {
            headers: this.createQueryHeaders(auth),
        });
    }

    get<T>(url: string, auth: boolean = true): Observable<T> {
        return this.http.get<T>(ApiService.createUrl(url), {
            headers: this.createQueryHeaders(auth),
        });
    }

    upload<T>(
        url: string,
        formData: FormData,
        auth: boolean,
        progress: (progress: number) => void = null,
        done: (media: Media) => void = null,
    ): void {
        this.http
            .post<T>(ApiService.createUrl(url), formData, {
                observe: 'events',
                reportProgress: true,
                headers: this.createQueryHeaders(auth, null),
            })
            .subscribe((event: HttpEvent<any>) => {
                if (event.type === HttpEventType.UploadProgress && progress) {
                    const progressValue = Math.round((100 * event.loaded) / event.total);
                    progress(progressValue);
                }
                if (event.type === HttpEventType.Response && done) {
                    done(event.body);
                }
            });
    }

    put<T>(url: string, obj: any, auth: boolean = true): Observable<T> {
        return this.http.put<T>(ApiService.createUrl(url), obj, {
            headers: this.createQueryHeaders(auth),
        });
    }

    delete(url: string, auth: boolean = true) {
        return this.http.delete(ApiService.createUrl(url), {
            headers: this.createQueryHeaders(auth),
        });
    }

    private createQueryHeaders(auth: boolean, contentType: string = 'application/json'): HttpHeaders {
        let headers: HttpHeaders = new HttpHeaders({
            Accept: 'application/json',
        });

        // Add authorization header if user is logged in and request needs auth
        if (auth) {
            const token = this.authService.token;
            if (token) {
                headers = headers.set('Authorization', 'Bearer ' + token);
            }
        }

        // Add content type header
        if (contentType) {
            headers = headers.set('Content-Type', contentType);
        }

        // Add platform header
        headers = headers.set('X-Platform', this.contextService.getPlatform());

        // Add language header
        headers = headers.set('Accept-Language', this.translateService.getLocale());

        return headers;
    }
}
