import { Component, inject } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Auth } from '@angular/fire/auth';
import { NavigationEnd, Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { filter } from 'rxjs';
import { register } from 'swiper/element/bundle';
import { AuthService } from './services/auth.service';
import { CategoryService } from './services/category.service';
import { CurrencyService } from './services/currency.service';
import { NotificationService } from './services/notification-service';
import { OrderService } from './services/order.service';
import { ShoppingCartService } from './services/shopping-cart.service';
import { StorageService } from './services/storage.service';
import { TranslateService } from './services/translate.service';
import { UserService } from './services/user.service';

register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    firebaseAuth = inject(Auth);
    showHeader = false;

    constructor(
        private readonly platform: Platform,
        private readonly storageService: StorageService,
        private readonly authService: AuthService,
        private readonly userService: UserService,
        private readonly router: Router,
        private readonly categoryService: CategoryService,
        private readonly translateService: TranslateService,
        private readonly orderService: OrderService,
        private readonly shoppingCartService: ShoppingCartService,
        private readonly exchangeRateService: CurrencyService,
        private readonly notificationService: NotificationService,
    ) {
        this.platform.ready().then(() => {
            this.init();
            this.listenRouterEvents();
        });
    }

    private async init(): Promise<void> {
        await this.storageService.init();
        await this.translateService.init();
        await this.exchangeRateService.init();

        // Init capasitor plugins
        if (this.platform.is('capacitor')) {
            // Set status bar overlay
            // StatusBar.setOverlaysWebView({ overlay: true });
            StatusBar.setStyle({ style: Style.Light });
            // Hide splash screen after 3 seconds
            setTimeout(() => {
                SplashScreen.hide();
            }, 2000);
        }

        this.shoppingCartService.init();

        // Retrieve categories
        this.categoryService.populateCategories();

        // Retrieve vats
        this.orderService.getVats('TR').subscribe();

        // Register push notifications
        if (this.platform.is('capacitor')) {
            await this.notificationService.init();
            await this.notificationService.register();
        }
    }

    private listenRouterEvents(): void {
        // NavigationEnd event, display header unless it's the on of the following pages
        const hideHeaderPages = ['/platform'];
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: any) => {
            if (event.url === '/') {
                this.showHeader = false;
                return;
            }
            console.log(event.url);
            let show = true;
            hideHeaderPages.forEach((page) => {
                if (event.url.startsWith(page)) {
                    show = false;
                }
            });
            this.showHeader = show;
        });
    }

    get backgroundClass(): string {
        return this.showHeader ? 'white' : 'light-grey';
    }
}
