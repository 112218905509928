import { Address } from './user';

export type CompanyActivity = 'real_estate' | 'car_dealership' | 'fashion' | 'other';

export class Company {
    id: number;
    name: string;
    description: string;
    vatNumber: string;
    proNumber: string;
    authorizationNumber: string;
    registrationNumber: string;
    code: string; // 5 characters code
    picture: number; // media id
    address: Address;
    searchHeader: number; // media id
    activity: CompanyActivity;
    shippingAddress: Address;
    returnAddress: Address;
    iban: string;
    shopName: string;
    shopNameSlug: string;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            this.address = data?.address ? new Address(data.address) : null;
            this.shippingAddress = data?.shippingAddress ? new Address(data.shippingAddress) : null;
            this.returnAddress = data?.returnAddress ? new Address(data.returnAddress) : null;
        }
    }

    get initials() {
        return this.name.slice(0, 2).toUpperCase();
    }
}

export class TeamAccountRequest {
    id: number;
    createdAt: string;
    updatedAt: string;
    company: number;
    companyName: string;
    userFullname: string;
}
