import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService as BaseTranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

export interface LocaleDefinition {
    name: string;
    code: string;
    flag: string;
}

@Injectable({
    providedIn: 'root',
})
export class TranslateService {
    private locale: string = environment.locales[0];
    public localeChanged = new EventEmitter<string>();
    static readonly AVAILABLE_LANGUAGES = environment.locales;
    static readonly LANGUAGES: LocaleDefinition[] = [
        // { name: 'English', code: 'en', flag: 'gb' },
        { name: 'Türkçe', code: 'tr', flag: 'tr' },
        { name: 'Français', code: 'fr', flag: 'fr' },
    ];

    constructor(
        private readonly translateService: BaseTranslateService,
        private readonly storage: StorageService,
    ) {}

    public get(key: string, params?: any): string {
        return this.translateService.instant(key, params);
    }

    public async init(): Promise<void> {
        // If locale is stored in storage, use it
        let locale = await this.storage.get<string>('locale');
        if (!locale) {
            locale = this.translateService.getBrowserLang();
        }
        if (!TranslateService.AVAILABLE_LANGUAGES.includes(locale)) {
            locale = environment.locales[0];
        }

        console.log(`Locale set to ${locale}`);

        this.translateService.use(locale);
        this.locale = locale;
        // Set locale moment
        moment.locale(locale);
        this.localeChanged.emit(locale);
    }

    getLocale(): string {
        return this.locale;
    }

    async setLocale(code: string): Promise<void> {
        console.log(`Locale changed to ${code}`);
        this.locale = code;
        this.translateService.use(code);
        await this.storage.set('locale', code);
        this.localeChanged.emit(code);
    }

    translateObject(obj: any): any {
        const translation: any = obj.translations.find((t: any) => t.locale === this.locale);
        if (translation) {
            // Copy all translation properties to source object
            for (const key in translation) {
                if (translation.hasOwnProperty(key) && key !== 'locale' && key !== 'id') {
                    obj[key] = translation[key];
                }
            }
        }

        return obj;
    }
}
