<ul class="card-list">
    <li class="clickable" (click)="openLanguageSelector()">
        {{ "Language" | translate }}
        <span>
            <strong>{{ selectedLanguage?.name | translate }}</strong>
            <!--          <i class="bi bi-pencil"></i>-->
        </span>
    </li>
    <li class="clickable">
        {{ "Country" | translate }}
        <span>
            <strong>{{ "Türkiye" | translate }}</strong>
            <!--        <i class="bi bi-pencil"></i>-->
        </span>
    </li>
    <li class="clickable" (click)="openCurrencySelector()">
        {{ "Currency" | translate }}
        <span>
            <strong>{{ selectedCurrency?.label | translate }}</strong>
            <!--<i class="bi bi-pencil"></i>-->
        </span>
    </li>
</ul>
