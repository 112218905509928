import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Category } from '../models/category';
import { CategoryUtils } from '../utils/category.utils';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    categories: BehaviorSubject<Category[]> = new BehaviorSubject<Category[]>(undefined);

    constructor(private readonly apiService: ApiService) {}

    private getCategories(): Observable<Category[]> {
        return this.apiService.get<Category[]>('/api/categories', false).pipe(map((data) => Category.toArray(data)));
    }

    populateCategories() {
        this.getCategories().subscribe((categories) => {
            console.log('categories as tree', categories);
            CategoryUtils.setCategories(categories);
            this.categories.next(categories);
        });
    }

    getPromotedCategories(): Observable<Category[]> {
        return this.apiService
            .get<Category[]>('/api/categories/promoted', false)
            .pipe(map((categories) => categories.map((c) => new Category(c))));
    }
}
