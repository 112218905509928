<!-- Main menu with categories -->
<ion-menu contentId="main-content" menuId="main-menu" id="main-menu">
    <ion-content>
        <div class="mx-3 h-100">
            <div
                class="h-100 d-flex flex-column justify-content-between align-items-stretch"
                *ngIf="!menuCategorySelected; else showMenuSubCategory"
            >
                <!-- Logo -->
                <div>
                    <ion-menu-toggle>
                        <a routerLink="/home" class="d-flex w-100 justify-content-center text-decoration-none" routerDirection="root">
                            <img ngSrc="/assets/avrazya-logo.svg" alt="Avrazya Logo" [width]="67" [height]="20" />
                        </a>
                    </ion-menu-toggle>
                    <div class="d-flex justify-content-start align-items-start flex-column gap-1 mt-3">
                        <ion-menu-toggle class="d-none d-lg-block">
                            <a class="menu-link" routerLink="/platform"
                                ><span class="bi bi-shuffle"></span> {{ "Change platform" | translate }}</a
                            >
                        </ion-menu-toggle>
                        <div class="w-100">
                            <div class="menu-link"><span class="bi bi-tags"></span> {{ "All categories" | translate }}</div>
                            <app-header-category-list
                                [categories]="categories"
                                (selectedCategory)="menuSelectCategory($event)"
                            ></app-header-category-list>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <ion-menu-toggle>
                        <a class="menu-link" routerLink="/settings"
                            ><span class="bi bi-gear-wide-connected"></span> {{ "Settings" | translate }}</a
                        >
                    </ion-menu-toggle>
                    <ion-menu-toggle>
                        <a class="menu-link" routerLink="/support"><span class="bi bi-info-circle"></span> {{ "Support" | translate }}</a>
                    </ion-menu-toggle>
                </div>
            </div>
            <ng-template #showMenuSubCategory>
                <div class="w-100 m-x">
                    <div (click)="menuCategoryGoBack()" class="clickable">
                        <span class="bi bi-chevron-left"></span>
                        {{ "Back" | translate }}
                    </div>
                    <div class="pt-3">
                        <div class="menu-link d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-start align-items-center">
                                <span class="bi bi-tag"></span>{{ menuCategorySelected.name }}
                            </div>
                            <ion-menu-toggle>
                                <a routerLink="/search" [queryParams]="{ category: menuCategorySelected.id }" class="see-all">{{
                                    "See all" | translate
                                }}</a>
                            </ion-menu-toggle>
                        </div>
                    </div>
                    <app-header-category-list
                        [categories]="childrenCategories"
                        (selectedCategory)="menuSelectCategory($event)"
                    ></app-header-category-list>
                </div>
            </ng-template>
        </div>
    </ion-content>
</ion-menu>

<!-- Main Header (desktop) -->
<div class="container-fluid d-none d-lg-flex justify-content-between align-items-center py-1" id="main-desktop-header">
    <div class="d-flex justify-content-start align-items-center gap-4">
        <!-- Logo -->
        <div class="d-flex align-items-end gap-2">
            <a routerLink="/home" class="text-decoration-none" routerDirection="root">
                <img ngSrc="/assets/avrazya-logo.svg" alt="Avrazya Logo" [width]="134" [height]="40" />
            </a>
            <a routerLink="/platform" class="d-inline-flex gap-1">
                <h1 class="fs-5 p-0 m-0">{{ platform | translate }}</h1>
                <i class="fs-5 bi bi-shuffle"></i>
            </a>
        </div>
    </div>
    <div>
        <!--        <app-search-form [searchForm]="searchForm"></app-search-form>-->
    </div>
    <div class="d-flex gap-4 align-items-center">
        <!-- Add an offer button -->
        <a routerLink="/create-offer" class="btn btn-sm btn-outline-danger d-flex align-items-center"
            ><span class="bi bi-plus"></span>{{ "Publish for free" | translate }}</a
        >

        <!-- Login Dropdown -->
        <!--        <ng-container *ngIf="!logged && !loginLoading">-->
        <!--            <div class="login-dropdown header-dropdown" [ngClass]="loginDropdownActive ? 'active' : ''" >-->
        <!--                <div class="d-flex align-items-center header-dropdown-selection" (click)="toggleLoginDropdown()">-->
        <!--                    {{ 'Login'|translate }} <span class="bi bi-caret-down-fill"></span>-->
        <!--                </div>-->
        <!--                <div class="header-dropdown-content right" [@loginDropdownAnimation]="loginDropdownActive ? '*' : 'void'">-->
        <!--                    <form (submit)="login()" class="mx-4 my-3" [formGroup]="loginForm">-->
        <!--                        <strong class="mb-2">{{ 'Login'|translate }}</strong>-->
        <!--                        <input class="mb-2 form-control form-control-sm" [placeholder]="'Email'|translate" formControlName="email">-->
        <!--                        <input class="mb-2 form-control form-control-sm" [placeholder]="'Password'|translate" formControlName="password" type="password">-->
        <!--                        <div class="d-flex justify-content-between">-->
        <!--                            <a routerLink="/signup" class="btn btn-sm btn-outline-dark">{{ 'Register'|translate }}</a>-->
        <!--                            <button class="btn btn-sm btn-primary" [disabled]="!loginForm.valid">{{ 'Login'|translate }}</button>-->
        <!--                        </div>-->
        <!--                    </form>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </ng-container>-->

        <!-- User Dropdown -->
        <!--        <ng-container *ngIf="logged && !loginLoading">-->
        <!--            <div class="user-dropdown header-dropdown" [ngClass]="userDropdownActive ? 'active' : ''">-->
        <!--                <div class="d-flex align-items-center header-dropdown-selection" (click)="toggleUserDropdown()">-->
        <!--                    {{ 'My account'|translate }} <span class="bi bi-caret-down-fill"></span>-->
        <!--                </div>-->
        <!--                <div class="header-dropdown-content right" [@userDropdownAnimation]="userDropdownActive ? '*' : 'void'">-->
        <!--                    <div class="d-flex flex-column mx-4 my-3">-->
        <!--                        <div class="mb-3">-->
        <!--                            <strong>{{ user?.fullname }}</strong><br/>-->
        <!--                            <span class="text-muted">{{ user.email }}</span>-->
        <!--                        </div>-->
        <!--                        <a routerLink="/profile/home" (click)="closeAllDropdowns()">{{ 'Profile'|translate }}</a>-->
        <!--                        <a routerLink="/profile/offers" (click)="closeAllDropdowns()">{{ 'Offers'|translate }}</a>-->
        <!--                        <a routerLink="/profile/stats" (click)="closeAllDropdowns()">{{ 'Stats'|translate }}</a>-->
        <!--                        <a routerLink="/profile/team" (click)="closeAllDropdowns()">{{ 'Team'|translate }}</a>-->
        <!--                        <a routerLink="/profile/ads" (click)="closeAllDropdowns()">{{ 'Ads'|translate }}</a>-->
        <!--                        <div class="mt-3 d-flex justify-content-end">-->
        <!--                            <button class="btn btn-sm btn-outline-dark" (click)="logout()">{{ 'Logout'|translate }}</button>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </ng-container>-->

        <ng-container *ngIf="loginLoading">
            <div>
                <ion-spinner></ion-spinner>
            </div>
        </ng-container>

        <div class="clickable"><span class="fs-4 bi bi-globe-europe-africa" (click)="openSettingsModal()"></span></div>

        <!-- Shopping Cart -->
        <a routerLink="/cart" class="text-decoration-none position-relative">
            <i class="fs-4 ml-2 bi bi-cart4"></i> <span class="sc-pill" *ngIf="shoppingCartLineCount > 0">{{ shoppingCartLineCount }}</span>
        </a>

        <a class="mobile-footer-link" routerLink="/profile" routerLinkActive="active">
            <span class="bi fs-4 bi-person-circle" *ngIf="!user"></span>
            <span class="bi fs-4 bi-person-check" *ngIf="user && !user.picture"></span>
            <app-picture *ngIf="user && user.picture" [picture]="user.picture" size="22" format="circle"></app-picture>
        </a>
    </div>
</div>
<div class="container-fluid d-none d-lg-flex d-xl-flex d-xxl-flex">
    <div class="horizontal-menu justify-content-start px-0 w-100">
        <div class="horizontal-menu-item">
            <span class="ps-0" (click)="openCategoryMenu()"><i class="bi bi-list"></i>&nbsp;{{ "All categories" | translate }}</span>
        </div>
        <!--        <div class="horizontal-menu-item"><a routerLink="/home" routerLinkActive="active">{{ 'Home'|translate }}</a></div>-->
        <div class="horizontal-menu-item" *ngFor="let category of promotedCategories">
            <a routerLink="/search" [queryParams]="{ category: category.id }" [class.active]="isActive(category)">{{ category.name }}</a>
        </div>
    </div>
</div>

<!-- Mobile Header -->
<div class="d-flex d-lg-none justify-content-between p-2" id="main-header-mobile">
    <div class="d-flex justify-content-start gap-2">
        <div class="d-flex" (click)="openCategoryMenu()">
            <i class="bi bi-list fs-2"></i>
        </div>
        <a routerLink="/home">
            <img ngSrc="/assets/avrazya-logo.svg" alt="Avrazya Logo" [width]="67" [height]="20" />
        </a>
    </div>
    <div class="d-flex justify-content-end align-items-center gap-3 pe-2"></div>
</div>
