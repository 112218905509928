import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { CustomFormModule } from '../custom-form.module';
import { FilterDisplayComponent } from './display/filter-display/filter-display.component';
import { FilterComponent } from './filter.component';
import { FilterFormCheckboxComponent } from './forms/filter-form-checkbox/filter-form-checkbox.component';
import { FilterFormCompositionComponent } from './forms/filter-form-composition/filter-form-composition.component';
import { FilterFormOpeningHoursComponent } from './forms/filter-form-opening-hours/filter-form-opening-hours.component';
import { FilterFormSelectComponent } from './forms/filter-form-select/filter-form-select.component';
import { FilterFormValueComponent } from './forms/filter-form-value/filter-form-value.component';
import { FilterSearchChoiceComponent } from './search/filter-search-choice/filter-search-choice.component';
import { FilterSearchRangeComponent } from './search/filter-search-range/filter-search-range.component';
import { FilterSearchTextComponent } from './search/filter-search-text/filter-search-text.component';

@NgModule({
    declarations: [
        FilterComponent,
        FilterFormValueComponent,
        FilterFormSelectComponent,
        FilterFormCompositionComponent,
        FilterFormOpeningHoursComponent,
        FilterDisplayComponent,
        FilterSearchChoiceComponent,
        FilterSearchTextComponent,
        FilterFormCheckboxComponent,
        FilterSearchRangeComponent,
    ],
    imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule, CustomFormModule],
    exports: [FilterComponent, FilterFormValueComponent],
})
export class FilterModule {}
