<div
    class="pp-container d-flex justify-content-center align-items-center"
    [style.width]="width"
    [style.height]="height"
    [style.aspect-ratio]="aspectRatio"
    [style.background-color]="bgColor"
    [ngClass]="[format, bgSize]"
>
    <ng-container *ngIf="loaded; else loading">
        <ng-container *ngIf="mediaUrl; else defaultPicture">
            <ion-img [src]="mediaUrl"></ion-img>
        </ng-container>
        <ng-template #defaultPicture>
            <div class="d-flex justify-content-center align-items-center w-100 h-100 bg-grey">
                <span class="bi bi-image fs-4" *ngIf="!default"></span>
                <strong class="fs-4" *ngIf="default">{{ default }}</strong>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #loading>
        <!-- loader -->
        <app-loader size="15"></app-loader>
    </ng-template>
</div>
