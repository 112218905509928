import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalContent } from '../../models/modal-content';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './default-modal.component.html',
    styleUrls: ['./default-modal.component.scss'],
})
export class DefaultModalComponent implements OnInit {
    @Input() content: ModalContent;

    constructor(private readonly modalCtrl: ModalController) {}

    ngOnInit() {
        console.log(this.content);
    }

    close(action: string) {
        this.modalCtrl.dismiss({ action });
    }
}
