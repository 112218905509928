import { Injectable } from '@angular/core';
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import Decimal from 'decimal.js';
import { BehaviorSubject, filter, firstValueFrom, map } from 'rxjs';
import { ExchangeRate } from '../models/exchange-rate';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

export type Currency = 'TRY' | 'EUR' | 'USD';

@Injectable({
    providedIn: 'root',
})
export class CurrencyService {
    private currency: Currency = 'TRY';
    private exchangeRates = new BehaviorSubject<ExchangeRate[]>(null);
    static currencies: { label: string; value: Currency }[] = [
        { label: _('Lire turque'), value: 'TRY' },
        { label: _('Euros'), value: 'EUR' },
        { label: _('Dollars'), value: 'USD' },
    ];

    constructor(
        private readonly apiService: ApiService,
        private readonly storageService: StorageService,
    ) {}

    public async init() {
        this.apiService
            .get<ExchangeRate[]>('/api/exchange-rates')
            .pipe(map((res) => res.map((exchangeRate) => new ExchangeRate(exchangeRate))))
            .subscribe({
                next: (rates) => {
                    this.exchangeRates.next(rates);
                },
                error: (err) => {
                    console.error(err);
                    this.exchangeRates.next([]);
                },
            });

        const currency = await this.storageService.get('currency');
        await this.setCurrency((currency as Currency) || 'TRY');
    }

    public async convert(amount: Decimal, currencySource: string): Promise<Decimal> {
        const currencyTarget = this.getCurrency();
        if (currencySource === currencyTarget) {
            return amount;
        }

        const rates = await firstValueFrom(this.exchangeRates.pipe(filter((rates) => !!rates)));
        console.log(rates);
        if (rates) {
            // Find exchange rate
            const exchangeRate = rates.find((rate) => rate.currencySource === currencySource && rate.currencyTarget === currencyTarget);
            if (exchangeRate) {
                return amount.mul(+exchangeRate.rate);
            }

            // No exchange rate found
            return amount;
        }
    }

    public getCurrency(): string {
        return this.currency;
    }

    async setCurrency(currency: Currency) {
        this.currency = currency;
        await this.storageService.set('currency', currency);
    }
}
