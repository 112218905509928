import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private deviceToken: string;
    private user: User;

    constructor(
        private readonly platform: Platform,
        private readonly apiService: ApiService,
        private readonly authService: AuthService,
    ) {}

    async init(): Promise<void> {
        this.authService.userLoggedIn.subscribe((user) => {
            if (user && this.deviceToken) {
                this.addDeviceTokenToUser(user, this.deviceToken);
            }
        });
        await PushNotifications.addListener('registration', (token) => {
            this.deviceToken = token.value;
            if (this.user) {
                this.addDeviceTokenToUser(this.user, token.value);
            }
        });
    }

    addDeviceTokenToUser(user: User, token: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            this.apiService
                .post('user/' + user.id + '/device-token', {
                    token,
                    name: (await Device.getInfo())?.name || 'Unknown',
                })
                .subscribe({
                    next: () => {
                        resolve();
                    },
                    error: (error) => {
                        reject(error);
                    },
                });
        });
    }

    async register(): Promise<void> {
        if (this.platform.is('capacitor')) {
            let permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === 'prompt') {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive !== 'granted') {
                return;
            }

            await PushNotifications.register();
        }
    }
}
