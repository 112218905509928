import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { EMPTY, Observable, catchError, from, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private readonly firebaseAuth = inject(Auth);

    constructor(private readonly authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 || error.status === 403) {
                    // Convert the Promise from getIdToken to an Observable
                    return from(this.firebaseAuth.currentUser.getIdToken(true)).pipe(
                        switchMap((token: string) => {
                            if (token) {
                                // Clone the request and set the new token header
                                const authReq = request.clone({
                                    headers: request.headers.set('Authorization', `Bearer ${token}`),
                                });
                                // Retry the request with the new token
                                return next.handle(authReq);
                            } else {
                                // If no token, proceed to logout
                                this.authService.logout(true);
                                // It's important to return an Observable here, even though we're logging out
                                return EMPTY;
                            }
                        }),
                        catchError((err) => {
                            // Handle if the token refreshment throws an error
                            this.authService.logout(true);
                            return EMPTY;
                        }),
                    );
                }
                // If the error is not 401 or 403, rethrow it
                return throwError(() => error);
            }),
        );
    }
}
