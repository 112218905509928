import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
    @Input() set size(v: string) {
        this.fontSize = (v || '32') + 'px';
    }
    fontSize: string;

    constructor() {}

    ngOnInit() {}
}
