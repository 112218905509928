<ul class="list-unstyled ms-3">
    <li *ngFor="let category of displayCategories" class="mt-1 fs-5">
        <ng-container *ngIf="category.children.length > 0; else menuToggle">
            <div class="d-flex justify-content-between align-items-center clickable" (click)="select(category)">
                <span>{{ category.name }}</span>
                <span class="bi bi-chevron-right"></span>
            </div>
        </ng-container>
        <ng-template #menuToggle>
            <ion-menu-toggle>
                <a routerLink="/search" [queryParams]="{ category: category.id }">{{ category.name }}</a>
            </ion-menu-toggle>
        </ng-template>
    </li>
</ul>
