export class SizeGuide {
    id: number;
    name: string;
    guide: {
        lines: SizeGuideLine[];
        columns: SizeGuideColumn[];
    };

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export interface SizeGuideLine {
    name: string;
    lineInputId: string;
    cells: SizeGuideCell[];
}

export interface SizeGuideCell {
    content: string;
    inputId: string;
}

export interface SizeGuideColumn {
    name: string;
    colInputId: string;
}
