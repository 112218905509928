import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Offer } from '../models/offer';
import { OfferVariant } from '../models/offer-definition';
import { ShoppingCart } from '../models/shopping-cart';
import { ContextService } from './context.service';
import { CurrencyService } from './currency.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartService {
    shoppingCart = new BehaviorSubject<ShoppingCart>(null);

    constructor(
        private readonly storageService: StorageService,
        private readonly contextService: ContextService,
        private readonly currencyService: CurrencyService,
    ) {}

    init() {
        this.storageService.get('shopping-cart').then(async (shoppingCart: unknown) => {
            if (!shoppingCart) {
                shoppingCart = new ShoppingCart();
            }
            (shoppingCart as ShoppingCart).wholesale = this.contextService.isWholesale();
            (shoppingCart as ShoppingCart).currency = this.currencyService.getCurrency();
            this.shoppingCart.next(new ShoppingCart(shoppingCart));
            await this.refreshPrices();
        });
    }

    async add(offer: Offer, offerVariant: OfferVariant, quantitySelected: number) {
        const shoppingCart = this.shoppingCart.value;
        shoppingCart.add(offer, offerVariant, quantitySelected);
        await this.refreshPrices();
        this.save(shoppingCart);
    }

    save(shoppingCart: ShoppingCart) {
        const obj: Partial<ShoppingCart> = {};
        obj.wholesale = shoppingCart.wholesale;
        obj.lines = shoppingCart.lines.map((line) => {
            return {
                offer: line.offer,
                offerVariant: line.offerVariant,
                quantity: line.quantity,
            };
        });
        this.storageService.set('shopping-cart', obj).then(() => {
            this.shoppingCart.next(shoppingCart);
        });
    }

    async remove(offerId: number, offerVariantId: number) {
        const shoppingCart = this.shoppingCart.value;
        // Keep lines with different offer id or offer id AND offer variant id
        shoppingCart.lines = shoppingCart.lines.filter(
            (l) => l.offer.id !== offerId || (l.offer.id === offerId && l.offerVariant?.id !== offerVariantId),
        );
        shoppingCart.updateGroups();
        await this.refreshPrices();
        this.save(shoppingCart);
    }

    async refreshPrices() {
        const shoppingCart = this.shoppingCart.value;

        // First, compute line totals
        shoppingCart.setLineTotal();

        // Convert group line prices
        for (const group of shoppingCart.groups) {
            for (const line of group.lines) {
                line.total.price = await this.currencyService.convert(line.total.price, line.total.currency);
                line.total.currency = this.currencyService.getCurrency();
            }
        }

        // Then, compute group totals
        shoppingCart.setGroupTotal();

        // Then compute total
        shoppingCart.setTotal();
    }

    empty() {
        const shoppingCart = new ShoppingCart();
        this.save(shoppingCart);
    }

    has(offer: Offer) {
        return this.shoppingCart.value.lines.find((l) => l.offer.id === offer.id) !== undefined;
    }
}
