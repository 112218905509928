export class RegexUtils {
    /**
     * Must be in the format :
     * - 12
     * - 12.5
     * - 12-13
     * - 12.5-13.5
     * - 12.5-13
     * - 12-13.5
     */
    static readonly SIZE_GUIDE_CELL_REGEX = /^(\d{1,3})(\.(\d))?(-(\d{1,3})(\.(\d))?)?$/;

    // 3 to 6 digits
    static zipRegex: RegExp = /^\d{3,6}$/;

    // Decimal (separator is a dot or a comma, 1 or 2 digits after the separator (optionnal), any number of digits before)
    static decimal: RegExp = /^-?\d+([.,]\d{0,2})?$/;

    // Integer (any number of digits)
    static integer: RegExp = /^-?\d+$/;

    // Sku can be any string of 5 to 30 characters, including letters, numbers, and special characters (/-.)
    static sku: RegExp = /^[a-zA-Z0-9\/\-]{5,30}$/;
}
