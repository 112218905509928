<div class="mobile-footer">
    <a class="mobile-footer-link" routerLink="/home" routerLinkActive="active">
        <span class="bi bi-house-door-fill"></span>
    </a>
    <a class="mobile-footer-link" routerLink="/platform" routerLinkActive="active">
        <span class="bi bi-shuffle"></span>
    </a>
    <div class="mobile-footer-link" routerLink="/create-offer" routerLinkActive="active">
        <div class="publish-icon">
            <span class="bi bi-cloud-plus"></span>
        </div>
    </div>
    <div class="mobile-footer-link" routerLink="/cart" routerLinkActive="active">
        <span class="bi bi-cart4"></span>
        <span class="sc-pill" *ngIf="shoppingCartLineCount > 0">{{ shoppingCartLineCount }}</span>
    </div>
    <div class="mobile-footer-link" routerLink="/profile" routerLinkActive="active">
        <span class="bi bi-person-circle" *ngIf="!user || !user.picture"></span>
        <app-picture *ngIf="user && user.picture" [picture]="user.picture" size="28" format="circle"></app-picture>
    </div>
</div>
