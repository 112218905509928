import { Component, Input, OnInit } from '@angular/core';
import { Media } from '../../models/media';
import { MediaService } from '../../services/media.service';

@Component({
    selector: 'app-picture',
    templateUrl: './picture.component.html',
    styleUrls: ['./picture.component.scss'],
})
export class PictureComponent implements OnInit {
    @Input() bgColor: string = '#fff';
    @Input() aspectRatio = '1:1';
    @Input() format: 'circle' | 'square' = 'square';
    @Input() bgSize: 'cover' | 'contain' = 'cover';
    @Input() default: string;
    media: Media;
    loaded = false;
    mediaUrl: string;
    width: string;
    height: string;

    @Input() set size(v: string) {
        // v is a string like '100x100' or '100' or '100% or '100%x100%'
        const [width, height] = v.split('x');
        this.aspectRatio = null;
        this.width = width.endsWith('%') ? width : `${width}px`;
        this.height = height ? (height.endsWith('%') ? height : `${height}px`) : this.width;
    }

    @Input() set picture(media: number | Media) {
        this.loaded = false;
        this.mediaUrl = null;
        if (typeof media === 'number') {
            // load media
            this.mediaService.get(media).subscribe({
                next: (media) => {
                    this.handleMedia(media);
                },
                error: () => {
                    this.loaded = true;
                },
            });
        } else {
            this.handleMedia(media);
        }
    }

    handleMedia(media: Media) {
        if (!media) {
            this.loaded = true;
            return;
        }
        this.media = media;
        // resolve media url
        this.mediaUrl = this.mediaService.resolveMedia(media.path);
        this.loaded = true;
    }

    constructor(private readonly mediaService: MediaService) {}

    ngOnInit() {}
}
