import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

export type StorageKey = 'locale' | 'redirectAfterLogin' | 'shopping-cart' | 'currency';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    memoryStorage: { [key: string]: any } = {};

    constructor(private readonly storage: Storage) {}

    async init(): Promise<void> {
        // Initialize storage
        await this.storage.create();
    }

    set<T>(key: StorageKey, data: T): Promise<any> {
        this.memoryStorage[key] = data;
        return this.storage.set(key, data);
    }

    get<T>(key: StorageKey): Promise<T> {
        return new Promise(async (resolve) => {
            // Check if data is in memory
            if (this.memoryStorage.hasOwnProperty(key)) {
                return resolve(this.memoryStorage[key]);
            }
            // Check if data is in storage
            resolve(await this.storage.get(key));
        });
    }

    remove(key: StorageKey): Promise<any> {
        if (this.memoryStorage.hasOwnProperty(key)) {
            delete this.memoryStorage[key];
        }

        return this.storage.remove(key);
    }
}
