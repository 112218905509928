import { inject, Injectable } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    authInitialized = new BehaviorSubject<boolean>(false);
    userLoggedIn = new BehaviorSubject<User | undefined>(undefined);
    token: string = null;
    private readonly firebaseAuth = inject(Auth);
    private readonly idToken$ = idToken(this.firebaseAuth);

    constructor(
        private readonly storageService: StorageService,
        private readonly navCtrl: NavController,
    ) {
        this.idToken$.subscribe((token) => {
            this.token = token;
        });
    }

    login(user: User): void {
        this.userLoggedIn.next(user);
    }

    saveRedirectAfterLogin(url: string): void {
        this.storageService.set('redirectAfterLogin', url);
    }

    getRedirectAfterLogin(): Promise<string> {
        return this.storageService.get('redirectAfterLogin');
    }

    removeRedirectAfterLogin(): void {
        this.storageService.remove('redirectAfterLogin');
    }

    logout(redirect: boolean = true): void {
        this.userLoggedIn.next(null);
        this.token = null;
        this.firebaseAuth.signOut();
        if (redirect) {
            this.navCtrl.navigateRoot('/home');
        }
    }

    isAuthenticated(): boolean {
        return this.firebaseAuth.currentUser !== null && this.firebaseAuth.currentUser !== undefined;
    }
}
