import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as SentrySibling from '@sentry/angular-ivy';
import * as Sentry from '@sentry/capacitor';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

defineCustomElements(window);
if (environment.production) {
    enableProdMode();
    console.log = () => {};
}

Sentry.init(
    {
        dsn: 'https://8a23310833fbee27d801b251aec51435@o4505904994058240.ingest.sentry.io/4505904995500032',
        release: 'avrazya-app@' + environment.version,
        dist: '1',
        enabled: environment.production,
        tracesSampleRate: 0.05,
        tracePropagationTargets: ['localhost', 'https://avrazya.com', 'https://mng.avrazya.com/api/'],
        integrations: [
            new SentrySibling.BrowserTracing({
                routingInstrumentation: SentrySibling.routingInstrumentation,
            }),
        ] as any,
    },
    // Forward the init method to the sibling Framework.
    SentrySibling.init,
);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
