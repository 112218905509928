import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';

export class Shipment {
    provider: 'PTT';
    weight: number;
    deci: string;
    daysToShip: number;
}

export class ShipmentObject {
    shipments: Shipment[];
    currency: string;
}

export interface PostShipment {}

export type OrderShipmentStatus =
    | 'packing'
    | 'ready'
    | 'shipped'
    | 'on_hold'
    | 'in_transit'
    | 'out_for_delivery'
    | 'delivered'
    | 'delayed'
    | 'returned'
    | 'cancelled';

export class OrderShipment {
    id: number;
    ref: string;
    provider: string;
    weight: number;
    tracking: string;
    status: OrderShipmentStatus;
    createdAt: string;
    updatedAt: string;
    barcode: string;
    code: string;

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    get statusFormatted(): string {
        switch (this.status) {
            case 'packing':
                return _('Packing');
            case 'ready':
                return _('Ready');
            case 'shipped':
                return _('Shipped');
            case 'on_hold':
                return _('On hold');
            case 'in_transit':
                return _('In transit');
            case 'out_for_delivery':
                return _('Out for delivery');
            case 'delivered':
                return _('Delivered');
            case 'delayed':
                return _('Delayed');
            case 'returned':
                return _('Returned');
            case 'cancelled':
                return _('Cancelled');
            default:
                return 'Unknown';
        }
    }

    get statusColor(): string {
        switch (this.status) {
            case 'delivered':
                return 'text-success';
            case 'returned':
            case 'cancelled':
                return 'text-danger';
            default:
                return 'text-warning';
        }
    }
}
