import { marker as _ } from '@colsen1991/ngx-translate-extract-marker';
import Decimal from 'decimal.js';
import { PriceObject } from './offer';
import { Order } from './order';

export type TransactionStatus = 'pending' | 'paid' | 'failed' | 'cancelled' | 'expired' | 'refunded';

export class Card {
    id: number;
    name: string;
    digits: string;
    expiresAt: string;
    brand: string;

    get nameFormatted() {
        return this.name?.toUpperCase() || '';
    }

    get brandFormatted() {
        return this.brand?.toUpperCase() || '';
    }

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }
}

export class Transaction {
    id: number;
    code: string;
    orders: Order[];
    paymentUrl: string;
    redirectToUrl: string;
    status: TransactionStatus;
    createdAt: string;
    amount: number;
    currency: string = 'TRY';

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    get amountPriceObject(): PriceObject {
        return { price: new Decimal(this.amount), currency: this.currency };
    }

    get statusFormatted(): string {
        switch (this.status) {
            case 'pending':
                return _('Pending');
            case 'paid':
                return _('Paid');
            case 'failed':
                return _('Failed');
            case 'cancelled':
                return _('Cancelled');
            case 'expired':
                return _('Expired');
            case 'refunded':
                return _('Refunded');
            default:
                return 'Unknown';
        }
    }

    get statusIcon(): string {
        switch (this.status) {
            case 'pending':
                return 'bi-clock';
            case 'refunded':
                return 'bi-arrow-repeat';
            case 'failed':
            case 'cancelled':
                return 'bi-x-circle';
            default:
                return 'bi-check-circle';
        }
    }

    get statusColor(): string {
        switch (this.status) {
            case 'pending':
                return 'text-warning';
            case 'refunded':
                return 'text-danger';
            case 'failed':
            case 'cancelled':
                return 'text-danger';
            default:
                return 'text-success';
        }
    }

    get statusDetailsFormatted(): string {
        switch (this.status) {
            case 'pending':
                return _('Your payment is pending');
            case 'failed':
            case 'cancelled':
                return _('Your payment has been cancelled');
            default:
                return _('Your payment has been completed');
        }
    }
}
