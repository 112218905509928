import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegexUtils } from '../utils/regex.utils';
import { ValidateCompositionFormGroup } from '../validators/composition.validator';

export type FilterType =
    | 'text' // user can fill a single value
    | 'decimal' // user can fill a single decimal value
    | 'date' // user can fill a single date value
    | 'integer' // user can fill a single integer value
    | 'single_choice' // user can pick a single value from the filter values
    | 'multi_choice' // user can pick multiple values from the filter values
    | 'year' // user can fill a single year value
    | 'composition' // user can pick multiple values from the filter values
    | 'opening_hours' // user can pick multiple values from the filter values
    | 'boolean'; // user can check a checkbox
export type ComponentType = 'value' | 'select' | 'composition' | 'opening_hours' | 'checkbox';
export type ComparisonType = 'eq' | 'neq' | 'gt' | 'gte' | 'lt' | 'lte' | 'in' | 'nin';
export type FilterSearchType = 'range';

export class FilterValue {
    id: number;
    code: string;
    value: string;

    constructor(obj?: any) {
        this.id = obj?.id;
        this.code = obj?.code;
        this.value = obj?.value;
    }

    static codeToArrayId(filter: Filter, codes: string | string[]): number[] {
        if (!Array.isArray(codes)) {
            codes = [codes];
        }
        const ids = (codes || []).map((code) => filter.filterValues.find((v) => v.code === code)?.id);
        return ids.filter((id) => id !== undefined);
    }

    static getFnFormat(type: FilterType): (value: any) => any {
        switch (type) {
            case 'decimal':
                return (value: string) => parseFloat(value);
            case 'date':
                return (value: string) => new Date(value);
            case 'boolean':
            case 'integer':
                return (value: string) => parseInt(value, 10);
            case 'year':
                return (value: string) => parseInt(value, 10);
            case 'single_choice':
                return (value: string | string[]): string => {
                    if (Array.isArray(value) && value.length > 0) {
                        return value[0];
                    }

                    return typeof value === 'string' ? value : '' + value;
                };
            default:
                return (value: string) => value;
        }
    }

    static checkCondition(value: any, filter: Filter, condition: FilterCondition): boolean {
        let a: any = value;
        let b: any = condition.value;

        if (filter.type === 'composition') {
            // TODO: check if the value is in the composition
            return false;
        }

        if (filter.type === 'opening_hours') {
            // TODO: check if the value is in the opening hours
            return false;
        }

        if (filter.type === 'boolean') {
            return parseInt(value) === parseInt(condition.value);
        }

        if (filter.type === 'year') {
            a = parseInt(a, 10);
            b = parseInt(b, 10);
        }

        if (filter.type === 'date') {
            a = new Date(a);
            b = new Date(b);
        }

        if (filter.type === 'decimal') {
            a = parseFloat(a);
            b = parseFloat(b);
        }

        if (filter.type === 'integer') {
            a = parseInt(a, 10);
            b = parseInt(b, 10);
        }

        if (filter.type === 'single_choice' || filter.type === 'multi_choice') {
            a = Array.isArray(a) ? a : [a];
            b = b.split(',');

            let type = condition.comparison;
            if (type === 'eq') {
                type = 'in';
            }
            if (type === 'neq') {
                type = 'nin';
            }
            if (type !== 'in' && type !== 'nin') {
                return false;
            }

            return FilterCondition.filterCompare(type, a, b);
        }

        if (condition.comparison === 'in' || condition.comparison === 'nin') {
            a = Array.isArray(a) ? a : [a];
            b = Array.isArray(b) ? b : [b];
        }

        // Check based on condition type
        return FilterCondition.filterCompare(condition.comparison, a, b);
    }
}

export class FilterTranslation {
    id: number;
    name: string;
    locale: string;

    constructor(obj?: any) {
        this.id = obj?.id;
        this.locale = obj?.locale;
        this.name = obj?.name;
    }
}

export class FilterCondition {
    id: number;
    comparisonFilters: number[];
    comparison: ComparisonType;
    value: string;
    filterConditionType: 'OR' | 'AND' = 'AND';

    constructor(obj?: any) {
        if (obj) {
            Object.assign(this, obj);
        }
    }

    /**
     * a is a value from the form, b is the value from the condition
     * @param comparison
     * @param a
     * @param b
     */
    static filterCompare(comparison: ComparisonType, a: any, b: any): boolean {
        switch (comparison) {
            case 'eq':
                return a === b;
            case 'neq':
                return a !== b;
            case 'gt':
                return a > b;
            case 'gte':
                return a >= b;
            case 'lt':
                return a < b;
            case 'lte':
                return a <= b;
            case 'in':
                return a.some((v: any) => b.includes(v));
            case 'nin':
                return !a.some((v: any) => b.includes(v));
            default:
                return false;
        }
    }
}

export class Filter {
    id: number;
    name: string;
    placeholder: string;
    icon: string;
    type: FilterType;
    filterValues: FilterValue[] = [];
    formControlName: string;
    isRequired: boolean = false;
    translations: FilterTranslation[] = [];
    mediaTag: string;
    searchable: boolean = true;
    searchLabel: string;
    conditions: FilterCondition[] = [];
    searchType: FilterSearchType;
    canCreateVariant: boolean;
    canCreateOfferGroup: boolean;
    displayLabel: string;
    createOfferGroupType: string;

    constructor(data?: any) {
        if (data) {
            Object.assign(this, data);
            this.filterValues = (data?.filterValues || []).map((v: any) => new FilterValue(v));
            this.translations = (data?.translations || []).map((t: any) => new FilterTranslation(t));
            this.conditions = (data?.conditions || []).map((c: any) => new FilterCondition(c));
        }
    }

    getValidators(search: boolean = false) {
        // Create validators from the type
        let regex: string;
        const validators = [];
        switch (this.type) {
            case 'integer':
            case 'year':
                regex = '[0-9]*';
                break;
        }
        // Add the pattern validator
        if (regex) {
            if (search && this.searchType === 'range') {
                // The expected format is a string with two values separated by a pipe
                regex = `^${regex}|${regex}$`;
            } else {
                regex = `^${regex}$`;
            }
            validators.push(Validators.pattern(regex));
        }

        // Add the required validator
        if (this.isRequired) {
            validators.push(Validators.required);
        }

        return validators;
    }

    static toComponentType(type: FilterType): ComponentType {
        switch (type) {
            case 'boolean':
                return 'checkbox';
            case 'text':
            case 'decimal':
            case 'date':
            case 'integer':
                return 'value';
            case 'year':
            case 'single_choice':
            case 'multi_choice':
                return 'select';
            case 'composition':
                return 'composition';
            case 'opening_hours':
                return 'opening_hours';
        }
    }

    formatValue(value: any): any {
        return FilterValue.getFnFormat(this.type)(value);
    }

    toFormControl(validators: any = []) {
        let control = null;
        // Set the input type
        switch (this.type) {
            case 'decimal':
                validators.push(Validators.pattern(RegexUtils.decimal));
                break;
            case 'integer':
                validators.push(Validators.pattern(RegexUtils.integer));
                break;
            case 'year':
                validators.push(...[Validators.max(2050), Validators.min(1800)]);
                break;
            case 'composition':
                control = new FormGroup({});
                validators.push(ValidateCompositionFormGroup);
                break;
        }

        if (this.isRequired) {
            if (['single_choice', 'multi_choice', 'composition'].includes(this.type)) {
                validators.push(Validators.minLength(1));
            } else {
                validators.push(Validators.required);
            }
        }

        if (control) {
            control.addValidators(validators);
            return control;
        }

        return new FormControl(null, validators);
    }

    toVariantFormControl(validators: any = []) {
        const isFilterValue = this.type === 'single_choice' || this.type === 'multi_choice';
        if (isFilterValue) {
            return new FormControl([], [Validators.required, Validators.minLength(1)]);
        } else {
            switch (this.type) {
                case 'decimal':
                    validators.push(Validators.pattern(RegexUtils.decimal));
                    break;
                case 'integer':
                    validators.push(Validators.pattern(RegexUtils.integer));
                    break;
                case 'year':
                    validators.push(...[Validators.max(2050), Validators.min(1800)]);
                    break;
            }

            const control = new FormArray([], [Validators.minLength(1)]);
            // We need to add a first value
            control.push(
                new FormGroup({
                    value: new FormControl('', [Validators.required, ...validators]),
                }),
            );

            return control;
        }
    }

    static codeToId(key: string) {
        const keyParts = key.match(/^(.*)_filter_(.*)$/);
        return parseInt(keyParts[2]);
    }

    getCode() {
        return this.name.toLowerCase().replace(/ /g, '_') + '_filter_' + this.id;
    }

    getColClass() {
        const componentType = Filter.toComponentType(this.type);
        switch (componentType) {
            case 'composition':
                return 'col-12';
            default:
                return 'col-6';
        }
    }

    canShow(values: { [key: string]: any }, filters: Filter[]): boolean {
        if (this.conditions.length === 0) {
            return true;
        }

        let filterInForm = false;

        // We need to check the form
        for (const condition of this.conditions) {
            const results: boolean[] = [];
            // Check if the filter is in the form
            for (let filterId of condition.comparisonFilters) {
                // Check if the filter exists
                const filter = filters.find((f) => f.id === filterId);
                if (!filter) {
                    results.push(false);
                    continue;
                } else {
                    filterInForm = true;
                }

                // Check if the filter is in the form and if it has a value
                const value = values?.[filter.getCode()];
                if (value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)) {
                    results.push(false);
                    continue;
                }

                // Check if the value matches the condition
                const result = FilterValue.checkCondition(value, filter, condition);
                results.push(result);
            }

            // "OR" => at least one condition must be true
            // "AND" => all conditions must be true
            const comparisonType = condition.filterConditionType;
            if (comparisonType === 'OR' && results.includes(true)) {
                return true;
            }
            if (comparisonType === 'AND' && !results.includes(false)) {
                return true;
            }
        }

        return !filterInForm;
    }
}

export type FilterValueType = string | number | boolean | string[] | number[] | boolean[];
